<template>
  <v-card class="inventory-item-card mx-auto">
    <v-img contain height="250" :src="item.image_url" />

    <v-card-title>
      {{ item.product.name }}
      <span v-if="item.current_price_per_unit" class="text-right price">${{ item.current_price_per_unit }}</span>
    </v-card-title>

    <v-card-text>
      <v-chip-group column>
        <div class="specification-item">
          <span>{{ $t('buy_request_qty') }} </span>
          <v-chip>{{ item.qty }} шт.</v-chip>
        </div>
      </v-chip-group>
    </v-card-text>

    <template v-if="item.is_active">
      <v-divider class="divider mx-4" />

      <v-card-actions class="text-right">
        <template v-if="item.user_id === customer.id">
          <v-btn @click="disableClick" color="red" text>
            <v-icon class="pr-2">mdi-sale</v-icon>
            {{ $t('decline') }}
          </v-btn>
        </template>
        <template v-else>
          <v-btn @click="sellClick" color="primary" text>
            <v-icon class="pr-2">mdi-store-clock</v-icon>
            {{ $t('sell') }}
          </v-btn>
        </template>
      </v-card-actions>
    </template>
  </v-card>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'BuyRequestItem',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      customer: 'user/getUser'
    })
  },
  methods: {
    ...mapActions({
      cancel: 'user/buy_request/cancel'
    }),
    disableClick () {
      this.cancel({
        buy_request_id: this.item.id
      })
    },
    sellClick () {

    }
  }
}
</script>
