<template>
  <v-dialog v-model="opened" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="text-h5">Create Buy Request</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-select v-model="product_id"
                        :items="products"
                        item-text="name"
                        item-value="id"
                        label="Product"
                        persistent-hint
                        single-line
                        required
              />
              <v-card v-if="selectedProduct">
                <v-list flat subheader three-line>
                  <v-subheader>Please, check details of selected product</v-subheader>
                  <v-list-item>
                    <v-list-item-content>
                      Growing percentage: {{ selectedProduct.growing_percentage }}
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      Maximal Growing Age: {{ maxGrowingAge }}
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      Growing stages amount: {{ selectedProduct.stages.length }}
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-text-field v-model="qty_for_buy" color="orange" label="Qty to request *" />
            </v-col>
          </v-row>
        </v-container>
        <small>*indicates required field</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closeDialog">
          Close
        </v-btn>
        <v-btn color="blue darken-1" text @click="submit">
          Request
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'CreateBuyRequestModal',
  data () {
    return {
      opened: false,
      qty_for_buy: 0,
      product_id: 0,
      products: [],
      productsLoading: false
    }
  },
  computed: {
    ...mapGetters({
      token: 'user/getAccessToken'
    }),
    selectedProduct () {
      return this.products.find(p => p.id === this.product_id)
    },
    maxGrowingAge () {
      return this.selectedProduct.max_growing_age ? this.selectedProduct.max_growing_age : 'Not set'
    }
  },
  methods: {
    ...mapActions({
      create: 'auction/buy_request/create',
      loadAvailableProducts: 'auction/buy_request/loadProducts',
      pushMessage: 'ui/pushMessage'
    }),
    submit () {
      this.create({
        product_id: this.product_id,
        qty: this.qty_for_buy
      })
        .then(() => {
          this.pushMessage({
            status: 'success',
            message: 'We\'ve received your buying request! Our manager will contact you soon!',
            delay: 2000
          })
        })
      this.closeDialog()
    },
    openDialog () {
      this.opened = true
      this.productsLoading = true
      this.loadAvailableProducts()
          .then(result => {
            this.products = result.data
            if (this.products.length) {
              this.product_id = this.products[0].id
            }
          })
          .finally(() => {
            this.productsLoading = false
          })
    },
    closeDialog () {
      this.opened = false
      this.qty_for_buy = 0
      this.product_id = 0
    }
  }
}
</script>
