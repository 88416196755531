<template>
  <v-container>
    <v-row class="text-center">
      <v-col>
        <v-checkbox
            v-model="inInventory"
            label="Products In Inventory"
            color="red"
            @change="refreshList"
            hide-details
        />
      </v-col>
    </v-row>
    <v-row class="text-center">
      <v-col>
        <v-card>
          <v-list three-line v-if="buyRequests.length > 0">
            <template v-for="(buyRequest, index) in buyRequests">
              <buy-request-item :key="buyRequest.id" :item="buyRequest" />
              <v-divider :key="index" />
            </template>
          </v-list>
          <template v-else>
            <p class="subheading font-weight-regular">
              Nobody is selling right now.
              <br />
              <i>
                But you always can check our auction for
                <router-link :to="'/auction/grower'">Grower Lots</router-link>
                or create <a @click.stop="openBuyRequestModal">buy request</a>
              </i>
            </p>
          </template>
        </v-card>
      </v-col>
    </v-row>
    <create-buy-request ref="createBuyRequestDialog" />
  </v-container>
</template>
<script>
import BuyRequestItem from '@/components/Auction/BuyRequestItem'
import CreateBuyRequest from '@/components/Auction/Modals/CreateBuyRequest'
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'SellTab',
  components: {
    BuyRequestItem,
    CreateBuyRequest
  },
  data: () => ({
    inInventory: false
  }),
  async mounted () {
    await this.refreshList()
  },
  computed: {
    ...mapGetters({
      buyRequests: 'auction/buy_request/getBuyRequests'
    })
  },
  methods: {
    ...mapActions({
      loadBuyRequests: 'auction/buy_request/loadBuyRequests'
    }),
    async refreshList () {
      await this.loadBuyRequests({
        products_in_inventory: this.inInventory
      })
    },
    openBuyRequestModal () {
      this.$refs.createBuyRequestDialog.openDialog()
    }
  }
}
</script>
